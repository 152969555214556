var create = require('../diagnostics');

/**
 * Create a new diagnostics logger.
 *
 * @param {String} namespace The namespace it should enable.
 * @param {Object} options Additional options.
 * @returns {Function} The logger.
 * @public
 */
var diagnostics = create(function prod(namespace, options) {
  options = options || {};
  options.namespace = namespace;
  options.prod = true;
  options.dev = false;

  if (!(options.force || prod.force)) return prod.nope(options);
  return prod.yep(options);
});

//
// Expose the diagnostics logger.
//
module.exports = diagnostics;
